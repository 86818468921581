const React = require('react');
const { string } = require('prop-types');
const classnames = require('classnames');
const { Image } = require('nordic/image');

const ICON_ID = 'cockade';
const namespace = 'ui-pdp-icon';

const IconCockade = ({ className }) => <Image src="cockade.svg" alt="" className={classnames(namespace, className)} />;

IconCockade.propTypes = {
  className: string,
};

IconCockade.defaultProps = {
  className: null,
};

module.exports = React.memo(IconCockade);
exports = module.exports;
exports.ICON_ID = ICON_ID;
